<template>
  <div class="p-grid dashboard">
    <!-- <div class="p-col-12 p-md-6 p-lg-3">
      <div class="p-grid overview-box overview-box-1">
        <div class="overview-box-title">
          <i class="pi pi-inbox"></i>
          <span>Unread Messages</span>
        </div>
        <div class="overview-box-count">1500</div>
        <div class="overview-box-stats">12 less than yesterday</div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="p-grid overview-box overview-box-2">
        <div class="overview-box-title">
          <i class="pi pi-map-marker"></i>
          <span>Check-ins</span>
        </div>
        <div class="overview-box-count">532</div>
        <div class="overview-box-stats">46 more than yesterday</div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="p-grid overview-box overview-box-3">
        <div class="overview-box-title">
          <i class="pi pi-folder"></i>
          <span>Files</span>
        </div>
        <div class="overview-box-count">450</div>
        <div class="overview-box-stats">30 more than yesterday</div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="p-grid overview-box overview-box-4">
        <div class="overview-box-title">
          <i class="pi pi-user"></i>
          <span>Users</span>
        </div>
        <div class="overview-box-count">532</div>
        <div class="overview-box-stats">250 more than yesterday</div>
      </div>
    </div> -->

    <div class="p-col-12 p-lg-12 global-sales">
      <div class="card">
        <h5>{{ $t('dashboard.todays_shifts') }}</h5>
        <ShiftsTable />
      </div>
    </div>

    <!-- <div class="p-col-12 p-lg-12 product-statistics">
      <div class="card">
        <h5>Product Statistics</h5>
        <table>
          <tbody>
            <tr>
              <td class="col-overview col-wait">
                <div>W</div>
              </td>
              <td class="col-status">
                <span class="status-time">Last Update: 12m ago</span>
                <span class="status-text">Currently Waiting</span>
              </td>
              <td class="col-numbers">
                <div>253 <span>Shipments</span></div>
                <div>584 <span>Orders</span></div>
              </td>
            </tr>
            <tr>
              <td class="col-overview col-success">
                <div>S</div>
              </td>
              <td class="col-status">
                <span class="status-time">Last Update: 12m ago</span>
                <span class="status-text">Successfully Completed</span>
              </td>
              <td class="col-numbers">
                <div>312 <span>Shipments</span></div>
                <div>409 <span>Orders</span></div>
              </td>
            </tr>
            <tr>
              <td class="col-overview col-delay">
                <div>D</div>
              </td>
              <td class="col-status">
                <span class="status-time">Last Update: 6m ago</span>
                <span class="status-text">Delayed</span>
              </td>
              <td class="col-numbers">
                <div>122 <span>Shipments</span></div>
                <div>341 <span>Orders</span></div>
              </td>
            </tr>
            <tr>
              <td class="col-overview col-preorder">
                <div>P</div>
              </td>
              <td class="col-status">
                <span class="status-time">Last Update: 15m ago</span>
                <span class="status-text">Preordered</span>
              </td>
              <td class="col-numbers">
                <div>221 <span>Shipments</span></div>
                <div>332 <span>Orders</span></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="p-col-12 p-lg-4">
      <div class="graph">
        <div class="p-grid">
          <div class="p-col-4">
            <span class="graph-title">Logins</span>
            <span class="graph-value">52003</span>
            <span class="graph-change">+32</span>
          </div>
          <div class="p-col-8">
            <img
              alt="Chart 1"
              src="/assets/layout/images/dashboard/graph-1.svg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="graph">
        <div class="p-grid">
          <div class="p-col-4">
            <span class="graph-title">Views</span>
            <span class="graph-value">532</span>
            <span class="graph-change">+24792</span>
          </div>
          <div class="p-col-8">
            <img
              alt="Chart 2"
              src="/assets/layout/images/dashboard/graph-2.svg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="graph">
        <div class="p-grid">
          <div class="p-col-4">
            <span class="graph-title">Sales</span>
            <span class="graph-value">$5521</span>
            <span class="graph-change">+243</span>
          </div>
          <div class="p-col-8">
            <img
              alt="Chart 3"
              src="/assets/layout/images/dashboard/graph-3.svg"
            />
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="p-col-12 p-md-6 p-lg-4 task-list">
      <div class="card">
        <h5>Tasks</h5>
        <ul>
          <li>
            <Checkbox
              name="task"
              value="reports"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Sales Reports</span>
            <i class="pi pi-briefcase"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="pay"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Pay Invoices</span>
            <i class="pi pi-file"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="dinner"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Dinner with Tony</span>
            <i class="pi pi-comments"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="meeting"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Client Meeting</span>
            <i class="pi pi-users"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="theme"
              v-model="tasksCheckbox"
            />
            <span class="task-name">New Theme</span>
            <i class="pi pi-palette"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="ticket"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Flight Ticket</span>
            <i class="pi pi-ticket"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="charts"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Generate Charts</span>
            <i class="pi pi-chart-bar"></i>
          </li>
          <li>
            <Checkbox
              name="task"
              value="client"
              v-model="tasksCheckbox"
            />
            <span class="task-name">Call Client</span>
            <i class="pi pi-mobile"></i>
          </li>
        </ul>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
      <div class="card">
        <h5>Contact Us</h5>
        <div class="p-grid">
          <div class="p-col-12">
            <Dropdown
              v-model="dropdownCity"
              :options="dropdownCities"
              optionLabel="name"
              placeholder="Select a City"
            />
          </div>
          <div class="p-col-12">
            <InputText
              type="text"
              placeholder="Name"
            />
          </div>
          <div class="p-col-12">
            <InputText
              type="text"
              placeholder="Age"
            />
          </div>
          <div class="p-col-12">
            <InputText
              type="text"
              placeholder="Email"
            />
          </div>
          <div class="p-col-12">
            <Textarea
              type="text"
              placeholder="Message"
            />
          </div>
          <div class="p-col-12">
            <Button
              type="button"
              label="Send"
              icon="pi pi-check"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4 contacts">
      <div class="card">
        <h5>Team</h5>
        <ul>
          <li class="clearfix">
            <img
              alt="User"
              src="/assets/layout/images/avatar.png"
              width="45"
            />
            <div class="contact-info">
              <span class="name">Madison Hughes (me)</span>
              <span class="location">jane@pn-manhattan.com</span>
            </div>
            <div class="contact-actions">
              <span class="connection-status online">online</span>
              <i class="pi pi-fw pi-comment"></i>
              <i class="pi pi-fw pi-reply"></i>
            </div>
          </li>
          <li class="clearfix">
            <img
              alt="Contact 1"
              src="/assets/layout/images/avatar1.png"
              width="45"
            />
            <div class="contact-info">
              <span class="name">Joshua Williams</span>
              <span class="location">joshua@pn-manhattan.com</span>
            </div>
            <div class="contact-actions">
              <span class="connection-status online">online</span>
              <i class="pi pi-fw pi-comment"></i>
              <i class="pi pi-fw pi-reply"></i>
            </div>
          </li>
          <li class="clearfix">
            <img
              alt="Contact 2"
              src="/assets/layout/images/avatar2.png"
              width="45"
            />
            <div class="contact-info">
              <span class="name">Emily Clark</span>
              <span class="location">emily@pn-manhattan.com</span>
            </div>
            <div class="contact-actions">
              <span class="connection-status offline">offline</span>
              <i class="pi pi-fw pi-comment"></i>
              <i class="pi pi-fw pi-reply"></i>
            </div>
          </li>
          <li class="clearfix">
            <img
              alt="Contact 3"
              src="/assets/layout/images/avatar3.png"
              width="45"
            />
            <div class="contact-info">
              <span class="name">Tim Johnson</span>
              <span class="location">tim@pn-manhattan.com</span>
            </div>
            <div class="contact-actions">
              <span class="connection-status online">online</span>
              <i class="pi pi-fw pi-comment"></i>
              <i class="pi pi-fw pi-reply"></i>
            </div>
          </li>
          <li class="clearfix">
            <img
              alt="Contact 4"
              src="/assets/layout/images/avatar4.png"
              width="45"
            />
            <div class="contact-info">
              <span class="name">David Stark</span>
              <span class="location">kelly@pn-manhattan.com</span>
            </div>
            <div class="contact-actions">
              <span class="connection-status offline">offline</span>
              <i class="pi pi-fw pi-comment"></i>
              <i class="pi pi-fw pi-reply"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="p-col-12 p-lg-8 chat">
      <div class="card">
        <h5>Chat</h5>
        <ul>
          <li class="clearfix message-from">
            <img
              alt="Contact 2"
              src="/assets/layout/images/avatar2.png"
            />
            <span>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</span>
          </li>
          <li class="clearfix message-own">
            <img
              alt="User"
              src="/assets/layout/images/avatar.png"
            />
            <span>Actually artisan organic occupy, Wes Anderson ugh whatever pour-over gastropub selvage.</span>
          </li>
          <li class="clearfix message-from">
            <img
              alt="Contact 2"
              src="/assets/layout/images/avatar2.png"
            />
            <span>Chillwave craft beer tote bag stumptown quinoa hashtag.</span>
          </li>
          <li class="clearfix message-own">
            <img
              alt="User"
              src="/assets/layout/images/avatar.png"
            />
            <span>Dreamcatcher locavore iPhone chillwave, occupy trust fund slow-carb distillery art party narwhal.</span>
          </li>
          <li class="clearfix message-own">
            <span>Sed ut perspiciatis unde omnis iste natus.</span>
          </li>
          <li class="clearfix message-from">
            <img
              alt="Contact 2"
              src="/assets/layout/images/avatar2.png"
            />
            <span>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse.</span>
          </li>
          <li class="clearfix message-own">
            <img
              alt="User"
              src="/assets/layout/images/avatar.png"
            />
            <span>At vero eos et accusamus.</span>
          </li>
        </ul>
        <div class="new-message">
          <div class="message-attachment">
            <i class="pi pi-paperclip"></i>
          </div>
          <div class="message-input">
            <input
              type="text"
              placeholder="Write a message"
              class="p-inputtext"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4">
      <div class="card timeline p-fluid">
        <div class="p-grid">
          <div class="p-col-3">
            <span class="event-time">just now</span>
            <i
              class="pi pi-globe"
              style="color:#3984b8"
            ></i>
          </div>
          <div class="p-col-9">
            <span
              class="event-owner"
              style="color:#3984b8"
            >Katherine May</span>
            <span class="event-text">Lorem ipsun dolor amet</span>
            <div class="event-content">
              <img
                alt="Bridge"
                src="/assets/layout/images/dashboard/bridge.png"
                width="100"
              />
            </div>
          </div>

          <div class="p-col-3">
            <span class="event-time">12h ago</span>
            <i
              class="pi pi-star-o"
              style="color:#f6ac2b"
            ></i>
          </div>
          <div class="p-col-9">
            <span
              class="event-owner"
              style="color:#f6ac2b"
            >Brandon Santos</span>
            <span class="event-text">Ab nobis, magnam sunt eum. Laudantium, repudiandae, similique!.</span>
          </div>

          <div class="p-col-3">
            <span class="event-time">15h ago</span>
            <i
              class="pi pi-comment"
              style="color:#7e8dcd"
            ></i>
          </div>
          <div class="p-col-9">
            <span
              class="event-owner"
              style="color:#7e8dcd"
            >Stephan Ward</span>
            <span class="event-text">Omnis veniam quibusdam ratione est repellat qui nam quisquam ab mollitia dolores ullam voluptates, similique, dignissimos.</span>
            <div class="event-content">
              <img
                alt="Nature"
                src="assets/demo/images/nature/nature1.jpg"
                width="100"
              />
            </div>
          </div>

          <div class="p-col-3">
            <span class="event-time">2d ago</span>
            <i
              class="pi pi-map-marker"
              style="color:#e175a0"
            ></i>
          </div>
          <div class="p-col-9">
            <span
              class="event-owner"
              style="color:#e175a0"
            >Jason Smith</span>
            <span class="event-text">Laudantium, repudiandae, similique!</span>
            <div class="event-content">
              <img
                alt="Map"
                src="/assets/layout/images/dashboard/map.png"
              />
            </div>
          </div>

          <div class="p-col-3">
            <span class="event-time">1w ago</span>
            <i
              class="pi pi-heart"
              style="color:#39b8b6"
            ></i>
          </div>
          <div class="p-col-9">
            <span class="event-owner">Kevin Cox</span>
            <span class="event-text">Quibusdam ratione est repellat qui nam quisquam veniam quibusdam ratione.</span>
          </div>

          <div class="p-col-3">
            <span class="event-time">2w ago</span>
            <i
              class="pi pi-compass"
              style="color:#3eb839"
            ></i>
          </div>
          <div class="p-col-9">
            <span
              class="event-owner"
              style="color:#3eb839"
            >Walter White</span>
            <span class="event-text">I am the one who knocks!</span>
          </div>

          <div class="p-col-12">
            <Button
              type="button"
              label="Refresh"
              icon="pi pi-refresh"
              class="rounded-btn raised-btn"
            ></Button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { ShiftsTable } from "@components";

  export default {
    components: {
      ShiftsTable,
    },
    data() {
      return {
        tasksCheckbox: [],
        dropdownCities: [
          { name: "New York", code: "NY" },
          { name: "Rome", code: "RM" },
          { name: "London", code: "LDN" },
          { name: "Istanbul", code: "IST" },
          { name: "Paris", code: "PRS" },
        ],
        dropdownCity: null,
        sales: [
          {
            order: 1,
            src: "/assets/layout/images/dashboard/flag-brazil.png",
            name: "BRAZIL",
            sales: 4234,
            change: "+35%",
          },
          {
            order: 2,
            src: "/assets/layout/images/dashboard/flag-china.png",
            name: "CHINA",
            sales: 3214,
            change: "-25%",
          },
          {
            order: 3,
            src: "/assets/layout/images/dashboard/flag-belgium.png",
            name: "BELGIUM",
            sales: 2842,
            change: "+28%",
          },
          {
            order: 4,
            src: "/assets/layout/images/dashboard/flag-france.png",
            name: "FRANCE",
            sales: 1942,
            change: "+15%",
          },
        ],
      };
    },
    methods: {
      formatCurrency(value) {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  /* Responsive */
  .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  @media screen and (max-width: 960px) {
    :deep(.p-datatable) {
      &.p-datatable-customers {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }
        .p-datatable-tbody > tr {
          border-bottom: 1px solid #dee2e6;
          > td {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;
            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
            }
            .p-progressbar {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
    :deep(.fc-header-toolbar) {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>
